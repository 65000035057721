.splash-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.logo {
  animation: App-logo-flip infinite 2s linear;
  height: 10vmin;
  pointer-events: none;
}

@keyframes App-logo-flip {
  0% {
    transform: perspective(800px) rotateY(0);
  }
  50% {
    transform: perspective(800px) rotateY(180deg);
  }
  100% {
    transform: perspective(800px) rotateY(360deg);
  }
}
